.authentication-container {
  display: flex;
  width: 900px;
  justify-content: space-between;
  margin: 30px auto;
}


@media screen and (max-width: 1200px)
{
  .authentication-container {
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: space-between;
  }
}