.category-page {
  min-height: 450px;
}

.category-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 20px;
  row-gap: 50px;
}

.category-title {
  text-align: center;
}

.category-information {
  text-align: center;
  letter-spacing: 0px;
  line-height: 30px;
  padding: 20px;
  font-size: 20px;
  margin: 0 auto 100px auto;
  width: 90%;
  border-radius: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.split-title {
  margin: 80px 0;
  font-size: 30px;
}
.container-image-family {
  max-width: 1100px;
  margin: 80px auto 100px auto;
}
.image-family {
  width: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 1200px)
{
  .category-page {
    min-height: unset;
  }
  .category-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .split-title {
    text-align: center;
    margin: 60px 0;
  }
}

@media screen and (max-width: 900px)
{
  .category-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 500px)
{
  .category-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .category-information {
    font-size: 15px;
  }

  .container-image-family {
    max-width: 1100px;
    margin: 0px auto 60px auto;
  }
}