.container-table {
  margin: 50px auto 0 auto;
  width: 70%;

  .title {
    color: #724a00;
  }
  td {
    border: 1px solid #333;
    padding: 20px;
  }
  
  .hidden {
    visibility: hidden;
  }
}


@media screen and (max-width: 900px)
  {
    .container-table {
      width: 95%;

      tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
      }

      td:last-child {
        border-top: unset;
      }
    }
    
  }
