.checkout-item-container {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;
  justify-content: center;

  .image-container {
    width: 22%;
    padding-right: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .name-label,
  .quantity,
  .price {
    width: 22%;
  }

  .name-label {
    display: flex;
    flex-direction: column;
  
    .name {
      font-weight: bold;
    }

    .label {
      font-size: 17px;
      font-style: italic;
      margin-top: 5px;
    }
  }

  .quantity {
    display: flex;

    .arrow {
      cursor: pointer;
    }

    .value {
      margin: 0 10px;
    }
  }

  .remove-button {
    text-align: center;
    cursor: pointer;
    width: 12%;
  }
}

@media screen and (max-width: 1200px)
{
  .checkout-item-container {
    font-size: 18px;
    .image-container {
      padding-right: 0;
    }
    .price, .quantity, .container-name-label {
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 5px;
    }
  }
}

@media screen and (max-width: 500px)
{
  .checkout-item-container {
   

    .name-label  {
      .name {
        text-align: center;
        font-size: 15px;
      }
      .label {
        text-align: center;
        font-size: 13px;
      }
    }
  }
}