.sign-up-container {
  display: flex;
  flex-direction: column;
  width: 380px;
  color: rgb(114, 74, 0);

  form {
    font-weight: bold;
  }
}

@media screen and (max-width: 1200px)
{
  .sign-up-container {
    width: 400px;
  }
}

@media screen and (max-width: 500px)
{
  .sign-up-container {
    width: 90%;
    justify-content: center;

    form button {
      width: 100%;
    }
  }
}