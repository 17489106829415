.product-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-weight: bold;
  cursor: pointer;
  
  img {
    width: 100%;
    height: 75%;
    object-fit: cover;
    margin-bottom: 5px;
  }

  button {
    width: 25%;
    opacity: 0.7;
    position: absolute;
    top: 45%;
    display: none;
  }

  &:hover {
    img {
      opacity: 0.8;
    }

    button {
      opacity: 0.85;
      display: flex;
    }
  }

  .footer {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 18px;
    margin-bottom: 15px;

    .name {
      display: flex;
      justify-content: flex-start;
      margin-left: 10px;
    }

    .price {
      display: flex;
      justify-content: flex-start;
      margin-left: 10px;
    }
  }
}

@media screen and (max-width: 900px)
{
  .product-card-container {
    img {
      width: 80%;
      height: 70%;
    }
    .footer {
      width: 80%;
      margin-bottom: 40px;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 500px)
{
  .product-card-container {
    
  }
}
