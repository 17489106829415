.container-delivery {
  display: flex;
}

@media screen and (max-width: 900px)
{
  .container-delivery {
    flex-direction: column;
  }
}

