.sign-in-container {
  display: flex;
  flex-direction: column;
  width: 380px;
  color: rgb(114, 74, 0);

  form {
    font-weight: bold;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    button {
      align-items: center; 
    }
    
    p {
      font-size: 15px;
      color: #000;
      margin: 20px 0 0 0;
    }
    
    .google-icon {
      width: 40px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1200px)
{
  .sign-in-container {
    margin-bottom: 100px;
    width: 400px;
  }
}

@media screen and (max-width: 500px)
{
  .sign-in-container {
    margin-bottom: 50px;
    width: 90%;

    .buttons-container {  
      button {
        margin: 15px 0;
      }

      p {
        margin: 10px 0;
      }
    }
  }
}