.delivery-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .radio {
    width: 90%;
    border: 1px solid #c9c9c9;
    height: 50px;
    display: flex;
    align-items: center;

    input {
      height: 20px;
      width: 20px;
      margin: 0 15px 5px 20px;
    }
  }

  form {
    width: 90%;
  }

  .subtitle {
    width: 80%;
    text-align: start;
    font-size: 20px;
  }
}

@media screen and (max-width: 900px)
{
  .delivery-container {
    order: 2;
    width: 100%;
    form , .radio {
      width: 80%;
    } 
  }
}