.cart-icon-container {
  width: 45px;
  height: 45px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  cursor: pointer;
  margin-left: 4px;
  .shopping-icon {
    width: 30px;
    height: 30px;
  }

  .item-count {
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    bottom: 9px;
    padding-bottom: 1px;
    color: rgb(0, 124, 6);
  }
}

@media screen and (max-width: 500px)
{
  .cart-icon-container {
    width: 26px;
  }
}
