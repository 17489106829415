.directory-item-container {
  min-width: 45%;
  height: 240px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  margin: 0 7.5px 15px;
  overflow: hidden;

  &:hover {
    cursor: pointer;

    & .background-image {
      transform: scale(1.1);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

    & .body {
      opacity: 0.9;
    }
  }

  &.large {
    height: 380px;
  }

  &:first-child {
    margin-right: 7.5px;
  }

  &:last-child {
    margin-left: 7.5px;
  }

  .background-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .body {
    height: 70px;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    background-color: white;
    opacity: 0.7;
    position: absolute;
    text-align: center;

    .title-category {
      font-weight: bold;
      margin: 5px 0 0 0;
      font-size: 25px;
      color: #4a4a4a;
    }
  }
}

@media screen and (max-width: 900px)
{
  .directory-item-container {
    .body {
      width: 35%;
      max-width: 200px;
      padding: 0 20px;

      .title-category {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 500px)
{
  .directory-item-container {
    .body {
      width: 35%;
      padding: unset;

      .title-category {
        font-size: 15px;
      }
    }
 
  }
}