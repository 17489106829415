.category-preview-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .cursor {
    cursor: pointer;
  }
  
  .preview {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 30px;
  }
}

@media screen and (max-width: 1200px)
{
  .category-preview-container {
    .preview {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media screen and (max-width: 900px)
{
  .category-preview-container {
    .preview {
      grid-template-columns: repeat(3, 1fr);
    }

    .title {
      text-align: center;
    }
  }
}

@media screen and (max-width: 500px)
{
  .category-preview-container {
    .preview {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
