body {
  min-width: 350px;
  margin: 0;
  margin-top: 190px;
  padding: 20px 40px;
  // font-family: 'Luckiest Guy', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  * {
    box-sizing: border-box;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  /* Chrome */
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin:0;
  }
  /* Opéra*/
  input::-o-inner-spin-button,
  input::-o-outer-spin-button { 
    -o-appearance: none;
    margin:0
  }
}
h1, h2, h3, h4, h5, a {
  color: rgb(114, 74, 0);
}

h1 {
  font-size: 35px;
  margin-bottom: 50px;
  letter-spacing: 1px;
}

h3 {
  font-size: 25px;
  margin: 10px 0;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 15px;
  margin: unset;
  font-weight: 500;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.red {
  color: rgb(161, 0, 0);
}

.success {
  color: #108b00;
}

form {
  display: flex;
  flex-direction: column;

  input {
    width: 98%;
    height: 40px;
  }

  textarea {
    width: 100%;
    height: 250px;
  }

  input, textarea {
    border: 1px solid rgb(201, 201, 201);
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 10px;
  }

  button {
    margin: auto;
    margin-top: 20px;
  }

  p {
    font-size: 12px;
  }
}

@media screen and (max-width: 900px)
{ 
  body {
    margin-top: 140px;
  }

  form {
    input {
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px)
{
  body {
    margin-top: 110px;
    padding: 20px 5px;
  }
}

