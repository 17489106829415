.cart-dropdown-container {
  position: absolute;
  width: 240px;
  height: 340px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  background-color: white;
  top: 90px;
  right: 40px;
  z-index: 9;

  .empty-message {
    font-size: 18px;
    margin: 50px auto;
  }

  .cart-items {
    height: 240px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }

  button {
    margin-top: auto;
  }

  .button-dropdown {
    display: flex;
    justify-content: center;
    font-size: 20px;

    .image {
      margin-top: 3px;
      margin-right: 5px;
      font-size: 23px;
    }
  }
}
