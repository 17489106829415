.title-text {
  text-align: center;
  margin: 50px 0 60px 0;
}

.video-container {
  background: rgb(16, 58, 0);
  margin: -30px auto 0 auto;
  height: 600px;
  width: 70%
}

@media screen and (max-width: 1400px)
{
  .video-container {
    height: 600px;
    width: 90%
  }
}

@media screen and (max-width: 1200px)
{
  .video-container {
    height: 500px;
  }
}

@media screen and (max-width: 900px)
{
  .video-container {
    height: 400px;
  }
}

@media screen and (max-width: 700px)
{
  .video-container {
    height: 300px;
    width: 95%;
  }
}

@media screen and (max-width: 540px)
{
  .video-container {
    height: 250px;
    width: 98%;
  }
}