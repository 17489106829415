.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .question-contact {
    text-align: center;
    margin-bottom: 40px;
  }

  form {
    width: 60%;
 
    .name-email-container {
      display: flex;
      margin-bottom: 40px;

      label {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .email {
        margin-left: auto;
      }

      .errorEmail {
        margin-left: 12px;
      }
    }

    svg {
      margin: auto;
    }
  }
}

@media screen and (max-width: 900px)
{
  .contact-container {
    form {
      width: 80%;

      .name-email-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;

        .errorEmail {
          margin-left: unset;
        }
      }
    } 
  }
}