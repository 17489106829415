.guarantee {
  position: relative;
  left: -40px; 
  right: -40px;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  background: #275045; 
  // background: #1f4037;  /* fallback for old browsers */
  // background: -webkit-linear-gradient(to top, #4b9170, #1f4037);  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to top, #4b9170, #1f4037); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: hidden;
  .container {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    .image {
      color: #fff;
      font-size: 60px;
      margin: auto 30px;
    }
  
    .block-guarantee {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 70px;
      color: #fff;
      border-right: 1px solid #fff;
      width: 100%;  
  
      .title {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}


@media screen and (max-width: 1200px)
{
  .guarantee {
    flex-direction: column;
    .container {
      width: 100%;
      border-bottom: 1px solid #fff;
      .block-guarantee {
        border-right: unset;
        
      }
    }
  }
}


@media screen and (max-width: 500px)
{
  .guarantee {
    min-width: 360px;
    left: -5px; 
  }
}