.cart-item-container {
  width: 100%;
  display: flex;
  height: 70px;
  margin-bottom: 15px;

  img {
    width: 30% !important;
  }

  .item-details {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 20px;
  }
}
