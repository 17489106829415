.container-about-us {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  .about-us {
    font-weight: bold;
    .title {
      margin: 40px 20px;
      font-size: 20px;
    }
    .link {
      color: #000;
      margin: 30px 20px;
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;
  
      &:hover {
        color: rgb(66, 66, 66);
      }
    }
  }

  .social-network-container {
    display: flex;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
