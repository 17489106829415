.checkout-container {
  width: 55%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0;

  .checkout-header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey;

    .header-block {
      color: rgb(114, 74, 0);
      text-transform: capitalize;
      width: 22%;

      &:last-child {
        width: 12%;
      }
    }
  }

  .total {
    margin: 50px 0 50px auto;
    font-size: 25px;
    font-weight: bold;
  }
}

@media screen and (max-width: 1200px)
{
  .checkout-container {
    width: 90%;
    margin: 0 auto;

    .header-block {
      text-align: center;
    }

    .total {
      text-align: center;
      margin: 50px 0 50px 0;
    }
  }
}

@media screen and (max-width: 500px)
{
  .checkout-container {
    width: 97%;
    font-size: 11px;

    .header-block {      
      &:last-child span {
        display: none;
      }
    }

    .total {
      font-size: 22px;
    }
  }
}
