.button-container {
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 20px 0 20px;
  background-color: #108b00;
  color: white;
  text-transform: uppercase;
  font-weight: bolder;
  border: 1px solid #000;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #53a847;
    color: #fff;
    border: none;
  }

  &.google-sign-in {
    background-color: #4285f4;
    color: white;
    font-size: 15px;
    line-height: 20px;


    &:hover {
      background-color: #357ae8;
      border: none;
    }
  }

  &.disabled {
    background-color: #dddddd;
    color: rgb(131, 131, 131);
    border: unset;
    pointer-events: none;
  }

  &.inverted {
    background-color: white;
    color: black;
    border: 1px solid black;
    font-size: 15px;

    &:hover {
      background-color: #108b00;
      color: white;
      border: none;
    }
  }

  &.show-product {
    background-color: white;
    color: black;
    border: 1px solid black;
    font-size: 13px;

    &:hover {
      background-color: #108b00;
      color: white;
      border: none;
    }
  }
}

@media screen and (max-width: 900px)
{
  .show-product {
    visibility: hidden;
  }
}
