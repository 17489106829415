footer {
  position: relative;
  bottom: -20px; 
  left: -40px; 
  right: -40px;
  width: 100vw;
  background-color: rgb(238, 238, 238);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 40px;
  margin-top: 40px;  
}

@media screen and (max-width: 500px)
{
  footer {
    left: -5px; 
    min-width: 360px;
    padding: 0 5px;
  }
}
