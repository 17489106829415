@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.navigation.scrollDown{
  opacity: 1; 
  animation: fadeOut 0.5s ease-in-out 0s forwards;
}

.navigation {
  z-index: 9;
  width: 100%;
  opacity: 0; 
  animation: fadeIn 0.5s ease-in-out 0s forwards;
  position: fixed;
  top: 0%;
  margin-left: -40px;
  padding-top: 10px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

  .logo-and-buttons-container {
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    padding: 0 40px;
  
    .sidebar {
      width: 33.33%;
    }

    .logo {
      cursor: pointer;
      width: 33.33%;
      text-align: center;
    }

    .nav-links-container {
      width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  
      .nav-link {
        padding: 10px 10px;
        cursor: pointer;
        color: rgb(114, 74, 0);
  
        &:hover {
          color: #108b00;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  
  .link-category-container {
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin: auto;

    .link-category {
      margin: 10px 20px 20px 20px;
      color: rgb(80, 80, 80);
      border-bottom: 0.5px solid transparent;
      cursor: pointer;
    }

    .link-category:hover {
      border-bottom: 0.5px solid #000;
      color: #000;
    }
   }
}

@media screen and (max-width: 900px)
{
  .navigation {
    height: 140px;
  }
}

@media screen and (max-width: 500px)
{
  .navigation {
    height: 110px;;
    margin-left: -5px;
    padding-top: unset;
    .logo-and-buttons-container {
      .logo img {
        width: 95px;
      }
  
      .nav-links-container {
        margin: auto;

        .nav-link {
          padding: unset;
          font-size: 13px;
          margin-right: 4px;
  
          &:hover {
            font-size: 14px;
          }
        }
      }
    }
  }
}
