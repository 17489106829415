.mentions-legales, .CGU {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow:hidden;

  p, ul {
    width: 90%;
    font-size: 18px;
  }

  .title {
    margin: 30px 0 10px 0;
    text-align: center;
  }

  .article {
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
    margin: 40px 0 0 0;
  }

  .dateCGU {
    text-align: end;
  }
}