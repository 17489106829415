.sidebar {
  .menu-bars {
    display: flex;
    justify-content: flex-start;
    color: #000;
    font-size: 30px;

    &:hover {
      cursor: pointer;
    }
  }
  
  .nav-menu {
    z-index: 1;
    display: flex;
    position: fixed;
    height: 100%;
    top: 0px;
    left: -100%;
    transition: 1000ms;
  }
  
  .nav-menu.active {
    width: 80%;
    left: 0;
    transition: 1000ms;
  }
  
  .close-button {
    color: #000;
    font-size: 30px;
    width: 100%;
    text-align: end;
    padding: 25px 25px 0 0;
    margin-bottom: 40px;
    cursor: pointer;
  }

  .sidebar-open-container {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    background-color: #fff;
    box-shadow: 1px 1px 12px #555;
  
    .sidebar-link-category {
      width: 100%;
      height: 50px;
      padding: 10px 40px;
      color: rgb(80, 80, 80);
      border-bottom: 0.5px solid transparent;
      cursor: pointer;
      
      &:hover {
        background-color: rgb(245, 245, 245);
        color: #000;
      }
    }
    .link-connexion {
      width: 50%;
      margin: auto 0 80px 40px;
      color: rgb(80, 80, 80);
      cursor: pointer;

      &:hover {
        color: #000;
      }

      .connexion-container {
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
        }
      }
    }

  }
}

