.rotate {
  transform: rotate(180deg);
}

.summary {
  width: 50%;
  border-left: solid 1px darkgray;
  
  h3, .show-summary, .checkout-item-container {
    text-align: center;
  }

  .quantity {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .promo {
    display: flex;
    flex-direction: column;
    padding: 40px 0 20px 50px;
    border-bottom: 1px solid darkgray;

    input {
      padding-left: 20px;
      margin-right: 20px;
    }

    .container-promo-items {
      display: flex;
      margin-bottom: 20px;
    }

    .info-code {
      font-style: italic;
    }
  }

  .subTotal, .delivery-price, .discount {
    margin: 20px 0 20px 50px;
  }

  .total {
    padding: 20px 0 20px 50px;
    border-top: 1px solid darkgray;
    font-weight: bold;
    border-bottom: 1px solid darkgray;
  }
}

@media screen and (max-width: 900px)
{
  .summary {
    order: 1;
    width: 100%;
    border-left: unset;

    .title-container {
      display: flex;
      align-items: center;
      justify-content: center;
      
      .title-summary {
        font-size: 18px;
        color:#108b00;
        font-weight: bold;
        font-style: italic;
        cursor: pointer;
        margin: 0 10px 0 0;
      }
    }
  

    .promo {
      padding: 40px 0 20px 20px;
    }

    .subTotal, .delivery-price, .discount {
      margin: 20px 0 20px 0px;
    }

    .total {
      padding: 20px 0 20px 0px;
    }
  }
}

@media screen and (max-width: 500px)
{
  .summary {
    .subTotal, .delivery-price, .discount {
      margin: 20px 0 20px 20px;
    }

    .total {
      padding: 20px 0 20px 20px;
      margin-bottom: 50px;
    }

    input {
      width: 180px;
    }

    .button-promo {
      min-width: 110px !important;
    }
  }
}
