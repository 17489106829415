

.box {
  height: 90%;
  width: 90%;
  padding: 75px 0 0 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: '1px solid #000';
  box-shadow: 24px;
  display:flex;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  overflow-x: 'hidden';
  overflow-y: scroll;

  .button-close {
    position: fixed;
    top: 4%;
    right: 4%;
    font-size: 40px;  
    color: rgb(114, 74, 0);
    cursor: pointer;
  }
  .main-container {
    width: 80%;
    margin: 50px auto 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .container-image {
      display: flex;
      justify-content: center;
      margin-right: 30px;

      .image-modal {
        object-fit: contain;
        width: 500px;
        height: 500px;
      }
    }

    .container-right {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 30px;

      .container-grey-summary {
        width: 500px;
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // background-color: rgb(215, 231, 202);
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
        border-radius: 10px;
        padding: 30px 50px;
      
        .title {
          font-size: 30px;
          color: rgb(114, 74, 0);
        }
    
        .input-quantity-container {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 20px;
    
          .input-quantity {
            width: 70px;
            height: 40px;
            margin: 0 10px;
            text-align: center;
          }
          .arrow {
            cursor: pointer;
            
            &:hover {
              color: #108b00;
            }
          }

          p {
            margin-right: 20px;
          }
        }

        .select-quantity {
          background-color: #f8f8f8;
          font-size: 16px;
          width: 250px;
          height: 50px;
          margin: 0px auto;
          cursor: pointer;
          text-align: center;
          border: none;

          &:focus {
            outline: none;
          }

          option:first-child{
            display: none;
          }
        }

        .button-validate {
          width: 200px;
          margin: 0 auto;
        }
      }
    }

    .unit-price {
      font-style: italic;
      font-weight: bold !important;
      font-size: 15px !important;
      color: rgb(112, 112, 112);
    }

    .total-price-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 50px;

      .total {
        font-size: 20px;  
        margin: unset;
        font-weight: bold;
      }
  
      .price {
        display: flex;
        justify-content: flex-end;
        color: rgb(16, 139, 0);
        font-size: 25px;
        font-weight: bold;
        margin-left: 7px;
      }
    }
  }
};

@media screen and (max-width: 1200px)
{
  .box {
    padding-top: 5px; 
    
    .button-close {
      right: 6%;
    }

    .main-container {
      flex-direction: column;

      .container-image {
        margin-right: unset;
        margin-bottom: 40px;
        
        .image-modal {
          width: 400px;
          height: 400px;
          margin-top: 75px;
        }
      }
      
      .container-right {
        width: 100%;
        margin-left: unset;

        .container-grey-summary {
          flex-direction: column;
          align-items: center;
          width: 400px;

          .title {
            width: 300px;
          }
          
          .input-quantity-container {
            margin: 20px 0 20px 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px)
  {
    .box {      
      width: 100%;
      height: 100%;

      .button-close {
        right: 8%;
      }

      .main-container {
        margin: 0 auto;

        .container-image {
          .image-modal {
            width: 300px;
            height: 300px;
          }
        }
        .container-right {
          .container-grey-summary {
            width: 300px;
            height: 400px;

            .title {
              font-size: 25px;
              padding: 0 10px;
            }
            
            .input-quantity-container p {
              font-size: 16px;
              margin: 0;
              width: 100px;
            }

            .select-quantity {
              margin: unset;
            }  
          } 
        }
        .total-price-container {
          .total, .price {
            font-size: 20px;
          }
        }
      } 
    }
  }
}